import sparkGenericDefaults from '../shopify-spark-generic/config.js';

export default {
  includes: ['shopify-spark-generic'],
  ...sparkGenericDefaults,
  Widgets: [
    ...sparkGenericDefaults.Widgets.filter((w) => w.name !== 'VerifyFitment'),
    {
      name: 'VerifyFitment',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/collapsibleVerifyFitment',
      initCollapsed: true,
      isAlwaysColumnLayout: true,
    },
  ],
};
