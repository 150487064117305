//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-444:_3252,_4548,_3012,_7344,_3920,_2692,_9948,_9832;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-444')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-444', "_3252,_4548,_3012,_7344,_3920,_2692,_9948,_9832");
        }
      }catch (ex) {
        console.error(ex);
      }